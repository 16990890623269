@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';







































































































.Registration_root {
  max-width: 400px;
  margin: 0 auto;

  input {
    font-size: 16px;
  }
}
.Registration_links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  margin: 0 0 25px;
  div {
    flex: 0 1 auto;
    a {
      color: #2af3f3;
      text-decoration: none;
    }
  }
}

.Registration_link {
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  margin: 15px 0;
  font-weight: 500;
  a {
    color: #2af3f3;
    text-decoration: none;
  }
}

.Registration_links {
  .Registration_checkbox-label {
    font-size: 12px;
    line-height: 22px;
    color: #ffffff;
    margin-left: 5px;
  }

  .v-input.v-input--selection-controls.v-input--checkbox {
    margin: 0;
    .v-input__control {
      .v-input__slot {
        .v-input--selection-controls__input {
          i {
            color: rgba(#fff, 0.04) !important;
          }
        }
      }
    }
  }
}
